<template>
  <div class="body">
    <div class="top-bg">
      <div class="bg-content">
        <div class="top-box">
          <div class="title">国美家招商</div>
          <div class="des">价值共创,实现生态共赢</div>
        </div>
        <div class="bg-news-box">
          <ul>
            <li v-for="item in news" :key="item.id" class="list">
              <div class="news-info">
                <span class="tips">NEW</span>
                <span class="content">{{ item.content }}</span>
              </div>
              <div class="date">{{ item.date }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--入驻商Mall-->
    <div>
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">SETTLED</h1>
          <h3 class="top_card-title">入驻商Mall</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="bottom_card">
          <div class="bottom-left-card" style="position: relative;">
            <div class="bottom-left-card-title">乐活秀</div>
            <div class="bottom-left-card-line"></div>
            <div class="bottom-left-card-content">
              国美家围绕“家 · 生活”战略打造线上/线下融合发展的本地生活数字化平台。聚焦项目周边1-8KM家庭客群，线上依托于乐活秀APP/小程序，到网、到家、到店商户全量入驻，线下依托国美家门店及周边生活服务点，通过商品展示体验、家延伸服务、家娱乐社交三大功能板块，为家庭用户提供全新的生活消费体验。
            </div>
            <div style="position: absolute;right: 0;" class="downAppBtn" @click="jumpUrl('/mall/step/mall')">
              <span>立即入驻</span>
              <i></i>
            </div>
          </div>
          <div class="bottom-right-card">
            <el-image
              class="pic bottom-right-card-img"
              :src="require('@/assets/img/phone1.png')"
              fit="cover"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
    <!--入驻乐活通-->
    <div class="gray-bg">
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">SETTLED</h1>
          <h3 class="top_card-title">入驻乐活通</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="bottom_card justifyEnd">
          <div class="bottom-left-card">
            <el-image
              class="pic bottom-right-card-img"
              :src="require('@/assets/img/phone2.png')"
              fit="cover"
            ></el-image>
          </div>
          <div>
            <div class="bottom-left-card-title">乐活通</div>
            <div class="bottom-left-card-line"></div>
            <div class="bottom-left-card-content">
              为商户提供撮合服务信息对接平台，为承接力商家、内容商家提供跨业态合作机会自发相互组合，商户在不增加任何固定成本情况下获得额外收益。通过乐活通平台，商户利用现有门店，实现更广泛的经营能力，入驻乐活通的商户同时优先成为国美家的储备商户。
            </div>
            <div class="downAppBtn" @click="jumpUrl('/joinType/match')">
              <span>立即入驻</span>
              <i></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--商家服务-->
    <div>
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">SERVICES</h1>
          <h3 class="top_card-title">商家服务</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="card-box">
          <div
            v-for="item in service"
            :key="`service${item.id}`"
            class="itemCard"
          >
            <el-image class="itemCardImg" :src="getImage(item.id)" fit="fill">
            </el-image>
            <div class="itemCardInfo">
              <el-image
                class="pic item-punctuation"
                :src="require('@/assets/img/punctuation.png')"
                fit="fill"
              ></el-image>
              <div class="item-content">{{ item.word }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--商家故事-->
    <div class="gray-bg">
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">STORY</h1>
          <h3 class="top_card-title">商家故事</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="bottom_card">
          <div class="bottom-left-card1">
            <div class="bottom-left-card-title">揭秘快充时刻如何成为食堂“爆款”！</div>
            <div class="bottom-left-card-line"></div>
            <div class="bottom-left-card-introduce">
              快充时刻虽为新生品牌，但创始人已有20余年餐饮行业经营经验。作为美食美客商务食堂第⼀批入驻商户，快充时刻深谙时效性的重要价值。商务人群中午休息时间有限，常因会议等原因未能准时下楼用餐。因此，为顾客提供及时、高效的服务是首先要解决的问题。如何建立起更加高效的出餐模式，提高出餐效率？如何在同⼀时间段内完成更多单量的售卖，降低客户的等待时间？快充时刻的老板⼀直在思考。
            </div>
          </div>
          <div class="bottom-right-card1">
            <el-image
              class="pic bottom-right-card-photo"
              :src="require('@/assets/img/kuaichongshike.png')"
              fit="fill"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
    <!--国美家介绍-->
    <div>
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">INTRODUCTION</h1>
          <h3 class="top_card-title">国美家介绍</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="bottom_card justifyEnd">
          <div class="bottom-right-card1 right">
            <el-image
              class="pic bottom-right-card-photo"
              :src="require('@/assets/img/story2.png')"
              fit="fill"
            ></el-image>
          </div>
          <div>
            <div class="bottom-left-card-title">国美家</div>
            <div class="bottom-left-card-line"></div>
            <div class="bottom-left-card-introduce">
              是国美集团旗下聚焦“新零售、新服务、全客群”,为家庭用户提供全新的生活消费体验的平台公司。国美家围绕“家 · 生活”战略打造线上/线下融合发展的本地生活数字化平台——乐活秀，聚焦项目周边1-8KM家庭客群，线上依托于乐活秀APP/小程序，到网、到家、到店商户全量入驻，线下依托国美家门店及周边生活服务点，通过商品展示体验、家延伸服务、家娱乐社交三大功能板块，为家庭用户提供全新的生活消费体验。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 入驻视频 -->
    <MerchantVideo
      :src="src"
      :dialog-visible="dialogVisible"
      @closeDialog="handleVisible"
    />
    <!-- 登录 -->
    <Login
      v-if="loginIsShow"
      :loginIsShow="loginIsShow"
      @loginVisible="loginVisible"
    />
  </div>
</template>

<script>
import MerchantVideo from "@/components/MerchantVideo.vue";
import Login from "@/components/Login.vue";
export default {
  data () {
    return {
      dialogVisible: false, //入驻视频弹出框是否显示
      src:
        "http://mp4.vjshi.com/2021-05-13/d0e55b2f8bdd2c536394ef93e1a3161a.mp4",
      loginIsShow: false, //登录弹出框是否显示
      news: [
        { id: 1, content: '海底捞签约入驻国美家商mall', date: '2033-03-07' },
        { id: 2, content: '海底捞签约入驻国美家商mall', date: '2033-03-07' },
        { id: 3, content: '海底捞签约入驻国美家商mall', date: '2033-03-07' },
        { id: 4, content: '海底捞签约入驻国美家商mall', date: '2033-03-07' },
        { id: 5, content: '海底捞签约入驻国美家商mall', date: '2033-03-07' },
        { id: 6, content: '海底捞签约入驻国美家商mall', date: '2033-03-07' },
        { id: 7, content: '海底捞签约入驻国美家商mall', date: '2033-03-07' },
      ],
      service: [
        { id: 1, img: '1', word: '帮助商家引流' },
        { id: 2, img: '2', word: '统一营销活动' },
        { id: 3, img: '3', word: '商户扶持方案' },
        { id: 4, img: '4', word: '一体化专业服务' },
      ],
    };
  },
  computed: {
    userName () {
      return this.$store.state.userName;
    },
  },
  // 注册子组件
  components: {
    MerchantVideo,
    Login,
  },
  mounted () {
    require('@/assets/img/bg_2.jpg')
    require('@/assets/img/bg_1.jpg')
  },
  methods: {
    getImage (str) {
      return require('../../assets/img/serve' + str + '.png')
    },

    handleVisible () {
      this.dialogVisible = !this.dialogVisible;
    },
    downloadApp () {
      this.$router.push("/index/downloadApp");
    },

    // 登录弹框事件
    loginVisible (status) {
      this.loginIsShow = status;
    },
    jumpUrl (url) {
      this.$router.push(url);
      // if (sessionStorage.getItem('user_token')) {
      // } else {
      //   this.$message.warning('请先登录');
      // }
    }
  },
};
</script>
<style scoped lang="scss">
.top-bg {
  height: 800px;
  position: relative;
  margin: 0 auto;
  background: url('~@/assets/img/bg21.png') no-repeat center 100%;
}
.top-text {
  width: 100%;
  height: 37px;
  text-align: center;
  margin: 20px 0 80px;
}
.top-btn {
  line-height: 1;
  font-size: 20px;
}
//解决大标题位置问题
/deep/.top-cont {
  width: 1200px;
  height: 800px;
  margin: auto;
  padding-top: 323px;
  padding-bottom: 182px;
  box-sizing: border-box;
  text-align: center;
}
/deep/.top-title {
  text-align: center;
  height: 98px;
  font-size: 70px;
  font-weight: 400;
  color: #ffffff;
  line-height: 98px;
  letter-spacing: 15px;
  text-shadow: 0px 30px 40px rgba(18, 20, 31, 0.5);
}
.bg-content {
  width: 1200px;
  // padding-top: 153px;
  padding-top: 366px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: center;
  text-align: center;
}
.top-box {
  color: #ffffff;
}
.title {
  // width: 428px;
  height: 89px;
  font-size: 70px;
  font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
  font-weight: bold;
  color: #ffffff;
  line-height: 89px;
  letter-spacing: 15px;
  text-shadow: 0px 30px 40px rgba(18, 20, 31, 0.5);
}
.des {
  width: 487px;
  height: 37px;
  font-size: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 37px;
  letter-spacing: 20px;
  text-shadow: 0px 10px 20px #283865;
  margin-top: 23px;
}
.bg-news-box {
  width: 490px;
  height: 519px;
  background: rgba(255, 255, 255, 0.2);
  // visibility: hidden;
  display: none;
}
.list {
  width: 489px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // font-family: MicrosoftYaHei;
}
.list:hover {
  background: linear-gradient(
    270deg,
    rgba(255, 134, 0, 0) 0%,
    rgba(255, 74, 45, 0.7) 100%
  );
}
.news-info {
  margin-left: 20px;
  color: #ffffff;
}
.tips {
  width: 28px;
  height: 8px;
  font-size: 12px;
  margin-right: 10px;
}
.active {
  background: linear-gradient(
    270deg,
    rgba(255, 134, 0, 0) 0%,
    rgba(255, 74, 45, 0.7) 100%
  );
}
.content {
  font-size: 18px;
  font-family: MicrosoftYaHei;
  line-height: 74px;
}
.date {
  margin-right: 19px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 74px;
}
.gray-bg {
  background: #f7f8f9;
}
.justifyEnd {
  justify-content: end;
}
.gome_introduce {
  width: 1200px;
  height: 800px;
  margin: auto;
  position: relative;
  .top_card {
    padding-top: 50px;
    height: 123px;
    opacity: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    .top_card-entitle {
      height: 94px;
      font-size: 78px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: rgba(31, 35, 41, 0.08);
      line-height: 94px;
    }
    .top_card-title {
      margin-top: -29px;
      font-size: 40px;
      font-family: HiraginoSansGB-W6, HiraginoSansGB;
      font-weight: normal;
      line-height: 40px;
      color: #1f2329;
    }
    .bottom_line {
      width: 60px;
      height: 8px;
      background: linear-gradient(90deg, #f6ba4f 0%, #ea8427 100%);
      border-radius: 4px;
      margin-top: 10px;
    }
  }
  .bottom_card {
    display: flex;
    align-items: flex-start;
    .bottom-left-card {
      margin-right: 242px;
    }
    .bottom-left-card1 {
      margin-right: 52px;
    }
    .bottom-left-card-title {
      width: 510px;
      font-size: 30px;
      font-family: HiraginoSansGB-W6, HiraginoSansGB;
      font-weight: normal;
      color: #1f2329;
      line-height: 30px;
      margin-top: 80px;
    }
    .bottom-left-card-line {
      width: 40px;
      height: 8px;
      background: linear-gradient(90deg, #f6ba4f 0%, #ea8427 100%);
      border-radius: 4px;
      margin-top: 10px;
    }
    .bottom-left-card-content {
      width: 601px;
      height: 223px;
      font-size: 20px;
      // font-family: MicrosoftYaHei;
      color: #666666;
      line-height: 40px;
      margin-top: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .bottom-left-card-introduce {
      width: 549px;
      height: 380px;
      font-size: 20px;
      // font-family: MicrosoftYaHei;
      color: #666666;
      line-height: 40px;
      margin-top: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .downAppBtn {
      width: 280px;
      height: 59px;
      background: linear-gradient(270deg, #ff8600 0%, #ff4a2d 100%);
      box-shadow: 0px 10px 15px 0px rgba(255, 84, 37, 0.3);
      border-radius: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 20px;
      margin-top: 55px;
    }
    .downAppBtn:hover {
      cursor: pointer;
    }
    .downAppBtn > i {
      display: block;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      border: 2px solid #ffffff;
      border-left: 0;
      border-bottom: 0;
      transform: rotate(45deg);
      margin-left: 10px;
    }
    .bottom-right-card {
      margin-top: 33px;
    }
    .bottom-right-card1 {
      margin-top: 80px;
    }
    .bottom-right-card-img {
      width: 228px;
      height: 494px;
    }
    .bottom-right-card-photo {
      width: 600px;
      height: 450px;
    }
  }
}
//卡片样式布局
.card-box {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 587px 587px;
  grid-template-rows: 250px;
  grid-row-gap: 27px;
  grid-column-gap: 26px;
}
.itemCard {
  width: 587px;
  height: 250px;
  border-radius: 10px;
  display: flex;
  position: relative;
}
.itemCardInfo {
  position: absolute;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
}
.item-punctuation {
  width: 34px;
  height: 30px;
  margin: 61px 15px 0 71px;
}
.item-content {
  height: 42px;
  font-size: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 42px;
  margin-top: 69px;
}
.right {
  margin-right: 53px;
}
</style>
